import React, { Component } from 'react';
import { graphql, navigate } from 'gatsby';
import ComponentsManager from '../../utils/components-manager';
import axios from 'axios';
import {
    FaFacebook,
    FaTwitter,
    FaYoutube,
    FaInstagram
} from 'react-icons/fa';
import Links from '../../utils/links';
import School from '../../utils/school';
import AssociatedColleges from '../internal/associated-colleges';
import iconBlog from '../../images/icons/icon-blog.svg';
import { ASSOCIATED_COLLEGES } from '../../constants/url';
import exploreFilters from '../explore/explore-filters';
import generateFilters from './../explore/generate-filters';

export default class SchoolLayout extends Component {

    state = {
        associatedColleges: [],
        school: new School(this.props.node)
    };

    componentDidMount = () => {
        if (typeof window !== 'undefined' && this.props.node.relationships.field_site_update.field_su_affiliated_colleges) {
            axios
                .get(`${window.backendUrl}${ASSOCIATED_COLLEGES}?ids=${encodeURI(this.props.node.relationships.field_site_update.field_su_affiliated_colleges)}`)
                .then(response => response.status === 200 && this.setState({ associatedColleges: response.data }));
        }
    };

    renderLogo = () => {
        const applyFYLink = `https://apply.commonapp.org/login?ma=${this.state.school.getMemberId()}&tref=3003`;
        const applyTRLink = this.state.school.getWebAdmOrgId()
            ? `https://apply.transfer.commonapp.org/applicant-ux/?tref=3003#/deeplink/programSearch/organization/${this.state.school.getWebAdmOrgId()}`
            : 'https://apply.transfer.commonapp.org/applicant-ux/?tref=3003';

        return (
            <>
                {this.state.school.getLogoUrl() && (
                    <div className="seal-container">
                        {/* todo: hardcoding max-width because we don't know the dimensions of the school seal image */}
                        <img src={this.state.school.getLogoUrl()} alt="School seal" style={{ maxWidth: '200px', paddingTop: '35px' }} />
                    </div>
                )}
                <div className="apply-button-container">
                    {
                        this.state.school.node.field_acc_md_fy_participation !== 'No' &&
                        <a className="text-center btn btn-lg" href={applyFYLink} rel="noopener noreferrer">Apply for first-year</a>
                    }
                    {
                        this.state.school.node.field_acc_md_tr_participation !== 'No' &&
                        <a className="text-center btn btn-lg" href={applyTRLink} rel="noopener noreferrer">Apply for transfer</a>
                    }
                </div>
            </>
        );
    };

    renderAdmissionsDetails = () => (
        <div className="admissions-details">
            <h3 className="admissions-office">Admissions office</h3>
            <h3 className="visit-us">Visit us</h3>
            <div className="school-map">
                <iframe
                    width="370"
                    height="230"
                    style={{border:"0"}}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBGWC4HXoHWtB24itRwmervuZj5fUzZCX0&q=${this.state.school.getLat()},${this.state.school.getLng()}`}>
                </iframe>
            </div>
            <div className="contant-info">
                <div>
                    <h5>Address</h5>
                    <p>
                        {this.props.node.relationships.field_site_update.field_su_addr_street}
                        {this.props.node.relationships.field_site_update.field_su_addr_street_2 ? ', ' + this.props.node.relationships.field_site_update.field_su_addr_street_2 : null}
                        <br />
                        {this.props.node.relationships.field_site_update.field_su_addr_city}
                        {this.props.node.relationships.field_site_update.field_su_addr_state ? ', ' + this.props.node.relationships.field_site_update.field_su_addr_state : null}
                        {this.props.node.relationships.field_site_update.field_su_addr_zip ? ' ' + this.props.node.relationships.field_site_update.field_su_addr_zip : null}
                        {this.props.node.relationships.field_site_update.field_su_addr_country ? ', ' + this.props.node.relationships.field_site_update.field_su_addr_country : null}
                    </p>
                </div>

                {this.props.node.relationships.field_site_update.field_su_addr_adm_email &&
                <div>
                    <h5>Email</h5>
                    <p>
                        <a href={"mailto:" + this.props.node.relationships.field_site_update.field_su_addr_adm_email}>
                            {this.props.node.relationships.field_site_update.field_su_addr_adm_email}
                        </a>
                    </p>
                </div>
                }

                {this.props.node.relationships.field_site_update.field_su_addr_adm_phone &&
                <div>
                    <h5>Phone number</h5>
                    <p>{this.props.node.relationships.field_site_update.field_su_addr_adm_phone}</p>
                </div>
                }

            </div>
            {this.state.school.isAcceptingFirstYearApplications() && this.renderContactLinks('first-year')}
            {this.state.school.isAcceptingTransferApplications() && this.renderContactLinks('transfer')}
        </div>
    );

    renderContactLinks = studentType => {
        let admissionsWebsite = this.props.node.relationships.field_site_update.field_su_addr_adm_website;
        let finAidWebsite = '';
        let guaranteedAdmWeb = '';
        let daca = '';

        if (studentType === 'first-year') {
            finAidWebsite = this.props.node.relationships.field_site_update.field_su_addr_fy_finaid_website;
            daca = this.props.node.relationships.field_site_update.field_su_addr_fy_daca || daca;
            admissionsWebsite = this.props.node.relationships.field_site_update.field_su_addr_fy_adm_website || admissionsWebsite;
        }
        if (studentType === 'transfer') {
            finAidWebsite = this.props.node.relationships.field_site_update.field_su_addr_tr_finaid_website;
            admissionsWebsite = this.props.node.relationships.field_site_update.field_su_addr_tr_adm_website || admissionsWebsite;
            guaranteedAdmWeb = this.props.node.relationships.field_site_update.field_transfer_guaranteed_admiss || guaranteedAdmWeb;
            daca = this.props.node.relationships.field_site_update.field_su_addr_ts_daca || daca;
        }

        if (!admissionsWebsite && !finAidWebsite && !daca && !guaranteedAdmWeb) {
            return null
        }

        return (
            <div className="contact-links">
                <h4>For {studentType} students</h4>
                {admissionsWebsite &&
                <div>
                    <h5>Admissions website</h5>
                    <p>
                        <a href={Links.fixSalesforceLink(admissionsWebsite)} target="_blank" rel="noopener noreferrer">{Links.fixSalesforceLinkText(admissionsWebsite)}</a>
                    </p>
                </div>
                }
                {finAidWebsite &&
                <div>
                    <h5>Financial aid website</h5>
                    <p>
                        <a href={Links.fixSalesforceLink(finAidWebsite)} target="_blank" rel="noopener noreferrer">{Links.fixSalesforceLinkText(finAidWebsite)}</a>
                    </p>
                </div>
                }
                {guaranteedAdmWeb &&
                <div>
                    <h5>Guaranteed admission website</h5>
                    <p>
                        <a href={Links.fixSalesforceLink(guaranteedAdmWeb)} target="_blank" rel="noopener noreferrer">{Links.fixSalesforceLinkText(guaranteedAdmWeb)}</a>
                    </p>
                </div>
                }
                {daca &&
                <div>
                    <h5>Undocumented or DACA students</h5>
                    <p>
                        <a href={Links.fixSalesforceLink(daca)} target="_blank" rel="noopener noreferrer">{Links.fixSalesforceLinkText(daca)}</a>
                    </p>
                </div>
                }
            </div>
        );
    };

    viewMoreInRegion = () => {
        const regionName = this.state.school.getRegion();
        const region = exploreFilters.map((filterGroup) => filterGroup.controls).reduce((acc, filter) => acc.concat(filter)).find(filter => filter.name === regionName);

        const params = new URLSearchParams();
        params.set('filter', btoa(JSON.stringify(generateFilters([region]))));
        navigate(`/explore/?${params.toString()}`);
    };

    renderRegionDetails = () => (
        <div className="region-details-container">
            <h3>Region</h3>
            {/* todo: hardcoded padding around image because we don't know the dimensions of region images */}
            <img src={this.state.school.getRegionImageUrl()} style={{ paddingTop: '15px', paddingBottom: '30px' }} alt={this.state.school.getRegion()} />
            <div className="region-details">
                <p className="region-name">{this.state.school.getRegion()}</p>
                <p>
                    <button onClick={() => this.viewMoreInRegion()}>View more in this region</button>
                </p>
            </div>
        </div>
    );

    renderSocialLinks = () => (
        <div className="school-social-container">
            <h5>Follow {this.props.node.title}</h5>
            <ul>
                {this.props.node.relationships.field_site_update.field_su_social_media_facebook &&
                <li>
                    <a href={Links.fixSalesforceLink(this.props.node.relationships.field_site_update.field_su_social_media_facebook)}
                       target="_blank"
                       rel="noopener noreferrer" aria-label="Visit us on facebook">
                        <FaFacebook size={18} title={"Facebook icon"} />
                    </a>
                </li>
                }
                {this.props.node.relationships.field_site_update.field_su_social_media_twitter &&
                <li>
                    <a href={Links.fixSalesforceLink(this.props.node.relationships.field_site_update.field_su_social_media_twitter)}
                       target="_blank"
                       rel="noopener noreferrer" aria-label="Visit us on twitter">
                        <FaTwitter size={18} title={"Twitter icon"} />
                    </a>
                </li>
                }
                {this.props.node.relationships.field_site_update.field_su_social_media_youtube &&
                <li>
                    <a href={Links.fixSalesforceLink(this.props.node.relationships.field_site_update.field_su_social_media_youtube)}
                       target="_blank"
                       rel="noopener noreferrer" aria-label="Visit us on youtube">
                        <FaYoutube size={18} title={"Youtube icon"} />
                    </a>
                </li>
                }
                {this.props.node.relationships.field_site_update.field_su_social_media_instagram &&
                <li>
                    <a href={Links.fixSalesforceLink(this.props.node.relationships.field_site_update.field_su_social_media_instagram)}
                       target="_blank"
                       rel="noopener noreferrer" aria-label="Visit us on instagram">
                        <FaInstagram size={18} title={"Instagram icon"} />
                    </a>
                </li>
                }
                {this.props.node.relationships.field_site_update.field_su_social_media_blog &&
                <li>
                    <a href={Links.fixSalesforceLink(this.props.node.relationships.field_site_update.field_su_social_media_blog)}
                       target="_blank"
                       rel="noopener noreferrer"
                       aria-label="Visit our blog" >
                        <img src={iconBlog} style={{ height: '18px', width: '18px', position: 'relative', top: '2px' }} alt="" />
                    </a>
                </li>
                }
            </ul>
        </div>
    );

    render = () => (
        <>
            <section className="container school-layout">
                <div className="row">
                    <div className="col-lg-8 school-main">
                        <ComponentsManager node={this.props.node} components={this.props.relationships.field_items} />
                    </div>
                    <div className="col-lg-4 col-side school-sidebar">
                        <div>
                            {this.renderLogo()}
                            {this.renderAdmissionsDetails()}
                            {this.renderRegionDetails()}
                            {this.renderSocialLinks()}
                        </div>
                    </div>
                </div>
            </section>
            <div className="associated-colleges-wrapper">
                {this.state.associatedColleges.length > 0 ? <AssociatedColleges schools={this.state.associatedColleges} /> : null}
            </div>
        </>
    );
}


/**
 * Most of the properties on this page are pulled from node directly:
 * this.props.node - eg. this.props.node.field_school_account_id
 * These fields are automatically synchronized with salesforce
 *
 */
export const SchoolLayoutQuery = graphql`
fragment ParagraphsSchoolLayout on paragraph__school_layout {
  relationships {
    field_items {
      __typename
      
         ...ParagraphsAccordion
      
        ...ParagraphsPlainAreaText
        ...ParagraphsMediaWithCaption
        ...ParagraphsQuoteStatement
        ...ParagraphsVideo
    }
  }
}
`;