import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

/**
 * This Resources
 */
export default class People extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props)
	  {
        super(props);
        this.data = props;
    }

    
    /**
     * Render person image
     * @param person
     * @returns {*}
     */
    renderImage(person)
    {
        if(person.relationships.field_image &&
            person.relationships.field_image.localFile)
        {
            return (
                <GatsbyImage image={person.relationships.field_image.localFile.childImageSharp.gatsbyImageData} alt={person.field_title + " image"} />
            )
        }
    }


    /**
     * Renders single resource card
     * @param key
     * @param person
     * @param chunkSize
     */
    renderPerson(key, person, chunkSize)
    {

        let classes = "person ";

        if(chunkSize === 1)
            classes += "col-12 col-md-6 col";

        if(chunkSize > 1)
            classes += "col-12 col-md-6 col";


        return (
            <div className={classes} key={key}>
                <div className="inner">
                    {this.renderImage(person)}
                    <div className="person-name">{person.field_title}</div>
                    <div className="person-title" dangerouslySetInnerHTML={{__html: person.field_text.processed}} />
                </div>
            </div>
        )

    }


    /**
     * @TODO move to utils
     * @param array
     * @param size
     * @returns {Array}
     */
     chunk(array, size) {
        const chunked_arr = [];
        for (let i = 0; i < array.length; i++) {
            const last = chunked_arr[chunked_arr.length - 1];
            if (!last || last.length === size) {
                chunked_arr.push([array[i]]);
            } else {
                last.push(array[i]);
            }
        }
        return chunked_arr;
    }

    handleSize () {
        const peopleBlock = document.querySelector('.people');
        const textBlock = document.querySelector('.plain-area-text');

        if(peopleBlock && textBlock){
            textBlock.classList.add('margin-reduced');
        } else {
            textBlock.classList.remove('margin-reduced');
        }
    }

    componentDidMount(){
        window.setTimeout(() => this.handleSize(), 0);
    }


    /**
     * Render Resources
     * @returns {*}
     */
    render()
    {
        const peopleRow = this.chunk(this.data.relationships.field_items, 2);

        return (
            <div className="people">
                {peopleRow.map((row, key) => (
                    <div className="row mb-3" key={key}>
                        {row.map((person, key) => this.renderPerson(key, person, row.length))}
                    </div>
                ))}
            </div>
        )


    }
};


export const PeopleQuery = graphql`
fragment ParagraphsPeople on paragraph__people {
    relationships {
      field_items {
        field_title
        field_text {
          processed
        }
        relationships {
          field_image {
            localFile {
                childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
          }
        }
      }
    }
  }
`;