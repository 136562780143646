import React, {Component} from 'react';
import {graphql, Link} from 'gatsby';

import airBalloonIllustration from '../images/icons/air-balloon-illustration.svg';
import iconBallon from '../images/icons/balloon.svg';
import iconArrow from '../images/icons/black-arrow.svg';

/**
 *
 */
export default class ThreeCardsBlueGradient extends Component {


    /**
     * Component constructor
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    /**
     * Generates header elements based on set properties
     * @returns {{header: *}} Header elements ready for rendering
     */
    renderHeader()
    {
        let headerSegments = [];
        const image = this.data.relationships.field_image?.localFile.publicURL;

        // If it has bg, add air baloons and stuff
        if(!this.data.field_no_background)
        {   
            if(image) {
                headerSegments.push(
                    (<img className="balloon-icon d-none d-lg-block d-xl-block" src={image}  key={2} alt={this.data.field_image?.alt}/>)
                ) 
            } else {
                headerSegments.push(
                    (<img className="balloon-icon d-lg-none d-xl-none" src={iconBallon} alt="air balloon icon" key={1}/>)
                )
                headerSegments.push(
                    (<img className="balloon-icon d-none d-lg-block d-xl-block" src={airBalloonIllustration}  key={2} alt="air balloon icon"/>)
                )
            }
        }

        // Render title only if title is populated
        if(this.data.field_title && this.data.field_title !== '')
        {
            headerSegments.push(
                (<h2 key={3}>{this.data.field_title}</h2>)
            )
        }

        // Render body only if it's populated
        if(this.data.field_text)
        {
            headerSegments.push(
                (
                    <div className="headline-text"
                         key={4}
                         dangerouslySetInnerHTML={{__html: this.data.field_text.processed}}>
                    </div>
                )
            )
        }
        return headerSegments
    }

    renderLink(link) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link to={link.alias}>
                    {link.title}
                    <img className="icon-1" src={iconArrow} alt="icon arrow" />
                </Link>
            );
        }

        return (
            <a target="_blank" rel="noopener noreferrer" href={link.alias}>
                {link.title}
                <img className="icon-1" src={iconArrow} alt="icon arrow" />
            </a>
        );
    }

    renderLinkTitle(link, title) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(link.alias);
        if (internal) {
            return (
                <Link to={link.alias}>
                    <h5>{title}</h5>
                </Link>
            );
        }

        return (
            <a target="_blank" rel="noopener noreferrer" href={link.alias}>
                <h5>{title}</h5>
            </a>
        );
    }

    /**
     * Renders a single card
     *
     * @TODO based on the number of cards we should add appropriate class, col-sm-4 might not always work, not sure
     */
    renderCard(key, card)
    {

        const lastCard = key === this.data.relationships.field_cards.length-1;
        const icon = (card.relationships.field_image && card.relationships.field_image.localFile && card.relationships.field_image.localFile.publicURL) ? card.relationships.field_image.localFile.publicURL : null;
        const title = card.field_title;
        const text = card.field_text.processed;

        return (
            <div className="col-md-4" key={key}>
                <div className={"col " + ((lastCard) ? ' cut-corner' : '')}>
                    <div className="img-holder">
                        { icon && <img height='80' src={icon} alt="icon"/> }
                    </div>
                    
                    {card.links.length > 0 ? (<>{this.renderLinkTitle(card.links[0], title)}</>) : (title?<h5>{title}</h5>: null)}
                    <div className="d-none d-lg-block d-xl-block" dangerouslySetInnerHTML={{__html: text}}>
                    </div>

                    {card.links.length > 0 && (<>{this.renderLink(card.links[0])}</>)}

                    
                </div>
            </div>
        )
    }


    /**
     * Component renderer
     * @returns {*}
     */
    render() {

        let classes = "plan-future";
        if(this.data.field_gradient_color === 'Teal') {
            classes += " teal"
        } else if(this.data.field_gradient_color === 'Navy') {
            classes += " navy"
        }
        if(this.data.field_no_background)
        {
            classes += " gray-bg";
        }

        return (
            <section className={classes}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-white">
                            {this.renderHeader().map((item, key) => item)}
                        </div>
                    </div>
                    <div className="row row-eq-height">
                        {this.data.relationships.field_cards.map((card, key) => this.renderCard(key, card))}
                    </div>
                </div>
            </section>
        )
    }

};


export const ThreeCardsBlueGradientQuery = graphql`
fragment ParagraphsThreeCardsBlueGradient on paragraph__three_cards_blue_gradient {
  field_title
  field_no_background
  field_gradient_color
  field_text {
    processed
  }
  field_image {
    alt
  }
  relationships {
    field_image {
        localFile {
            publicURL
        }
    }
    field_cards {
      field_title
      field_text {
        processed
      }
      links {
        alias
        title
        fieldname
      }
      
      relationships {
        field_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
}
`;
