import React, {Component} from 'react';
import { graphql } from 'gatsby';
import Links from "../utils/links";

/**
 *
 */
export default class BluePhotoBanner extends Component {

    /**
     * Constuct components manager
     * @param props
     */
    constructor(props) {
        super(props);
        this.data = props;
    }

    /**
     *
     * @returns {*}
     */
    renderButton()
    {
        if(this.data.links.length === 0)
        {
            return null;
        }

        const link = this.data.links[0];
        let buttonClass = "btn btn-lg "
        if(this.props.field_gradient_color === "Navy") {
          buttonClass+= "btn-dark"
        } else {
          buttonClass+= "btn-white"
        }
        return (
            Links.renderLinkKeySelf(link, buttonClass)
        )
    }

    renderImage()
    {

        if(this.data && this.data.relationships && this.data.relationships.field_image && this.data.relationships.field_image.localFile){
            return (<img src={this.data.relationships.field_image.localFile.publicURL} alt={this.props.field_image.alt} />);
        }
        return '';
        
    }


    /**
     * Render menu
     * @returns {*}
     */
    render()
    {
        let classes = "application-banner-inner"
        if(this.props.field_gradient_color === 'Teal') {
          classes+= " gradient-teal"
        } else if (this.props.field_gradient_color === 'Navy') {
            classes+= " gradient-navy"
        }
        if (this.props.field_text?.processed) {
          classes+=" subtext"
        }        
        return (
            <section className="application-banner">
              <div className="container">
                <div className={classes}>
                  <div className="row align-items-center">
                      <div className="col-xs-12 col-sm-5 col-lg-4 col">
                          <div className="content">
                              {this.renderImage()}
                          </div>
                      </div>
                      <div className="col-xs-12 col-sm-6 col-lg-7">
                          <div className="content">
                              <h2 className="text-white">{this.data.field_title}</h2>
                              <div dangerouslySetInnerHTML={{__html: this.data.field_text?.processed}}></div>
                              {this.renderButton()}
                          </div>
                      </div>
                    </div>
                  </div>
              </div>
            </section>
        )
    }
};

export const BluePhotoBannerQuery = graphql`
fragment ParagraphsBluePhotoBanner on paragraph__blue_photo_banner {
  field_title
  field_gradient_color
  field_text {
    processed
  }
  field_image {
    alt
  }
  links {
    alias
    title
    fieldname
  }
  relationships {
    field_image {
      localFile {
        publicURL
      }
    }
  }
}
`;