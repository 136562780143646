import React, { Component } from 'react';
import iconNoResults from "../../images/icons/icon-no-results.svg";
import { chunkArray } from "../../utils/chunkArray";
import { insertIntoArray } from "../../utils/insertIntoArray";
import { deepCloneArray } from '../../utils/deep-clone';
import { Link, navigate } from "gatsby";
import * as PropTypes from "prop-types";
import InfiniteScroll from 'react-infinite-scroller';
import capitalize from '../../utils/capitalize';
import ExploreCard from "./explore-cards";

//import imagePlaceholder from '../../images/school-fallback-image.svg';

/**
 * Header component with search functionality + autocomplete
 */
class ExploreResultsGrid extends Component {
    constructor(props) {
        super(props);

        this.exploreCards = props.cards;

        this.state = {
            shownGroups: [],
            moreItems: [],
        };


    }

    loadItems(loadChunk = 3) {
        const loadThese = this.state.moreItems.slice(0, loadChunk);
        const moreItemsNew = this.state.moreItems.slice(loadChunk);

        this.setState({
            shownGroups: this.state.shownGroups.concat(loadThese),
            moreItems: moreItemsNew
        }, () => {
            //console.log("Currently shown groups: ", this.state.shownGroups.length, "More items", this.state.moreItems.length)
        });

    }

    componentDidMount() {
        this.getSchoolGroups(this.props.schools, () => {
            const rawLoadChunk = sessionStorage.getItem('__ca-explore-load-chunk');
            if (rawLoadChunk) {
                const loadThese = JSON.parse(rawLoadChunk);
                this.loadItems(loadThese);
            }
        });
    }

    componentWillUnmount () {
        sessionStorage.setItem('__ca-explore-load-chunk', this.state.shownGroups.length - 5);
    }

    UNSAFE_componentWillReceiveProps(nextProps)
    {
        //console.log("PROPS CHANGED", nextProps.schools);
        this.getSchoolGroups(nextProps.schools);
    }

    render() {
        if (!this.props.schools || this.props.schools.length === 0) {
            return this.renderNoCollegesMessage();
        }


        return (
            <div className="school-grid-container">
                <div className="school-grid-wrapper">

                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadItems.bind(this)}
                        hasMore={this.state.moreItems.length > 0}>

                        {
                            this.state.shownGroups.map((schoolGroup, key) => this.renderSchoolGroup(schoolGroup, key))
                        }
                    </InfiniteScroll>
                    {
                        this.props.isInPrintMode ?
                            <div>
                                {
                                    this.state.moreItems.map((schoolGroup, key) => this.renderSchoolGroupForPrint(schoolGroup, key))
                                }
                            </div> :
                            null
                    }
                </div>
            </div>
        );
    }

    getExploreCards()
    {
        let results = [];

        this.exploreCards.forEach((card, key) => {
            const imageUrl = (card.relationships.field_image && card.relationships.field_image.localFile) ? card.relationships.field_image.localFile.publicURL : null;
            const cardComponent = () => { return (<ExploreCard key={key}
                                                               title={card.field_title}
                                                               content={card.field_text.processed}
                                                               image={imageUrl}
                                                               links={card.links} />) };
            results.push(cardComponent);
        });

        return results;
    }

    getSchoolGroups(schools, callback = () => {}) {
        // const sortedSchools = [...this.state.searchResults]
        //     .sort((currentSchool, nextSchool) => sortingFunctions[this.state.sortBy](currentSchool, nextSchool));

        const chunkedSchools = chunkArray(deepCloneArray(schools), 3);
        const groups = insertIntoArray(chunkedSchools, [...this.getExploreCards()]);

        //console.log("GROUPS TOTAL", groups.length);

        // const shownGroups = groups.slice(0, 5);
        // const moreItems = groups.slice(5);
        //console.log(groups);
        this.setState({
            shownGroups: groups.slice(0, 5),
            moreItems: groups.slice(5),
        }, () => callback());

        return groups;
    }

    renderNoCollegesMessage() {
        return (
            <div className="no-college-message">
                <img src={iconNoResults} alt="College campus inside magnifying glass." />
                <span>There are no colleges that match your search</span>
                <span>Try removing one of more filters to get better results</span>
                <div>
                    <button onClick={() => this.onClearFilters()}>Clear filters</button>
                </div>
            </div>
        );
    }

    onClearFilters () {
        navigate('/explore/');
    }

    renderSchoolGroup(schoolGroup, key) {
        if (typeof schoolGroup === 'undefined')
            return null;
        
        return (
            <div
                key={key}
                className="school-grid container">
                {
                    Array.isArray(schoolGroup) ?
                        schoolGroup.map((school, index) => this.renderSchool(school, index)) :
                        schoolGroup()
                }
            </div>
        );
    }

    renderSchoolGroupForPrint(schoolGroup, key) {
        if (typeof schoolGroup === 'undefined')
            return null;
        
        return (
            <div
                key={key}
                className="school-grid container">
                {
                    Array.isArray(schoolGroup) ?
                        schoolGroup.map((school, index) => this.renderSchoolForPrint(school, index)) :
                        schoolGroup()
                }
            </div>
        );
    }

    getAcceptingStudentType (school) {
        const acceptedStudentTypes = [];
        if (school.isAcceptingFirstYearApplications()) {
            acceptedStudentTypes.push('first-year');
        }
        if (school.isAcceptingTransferApplications()) {
            acceptedStudentTypes.push('transfer');
        }
        return capitalize(acceptedStudentTypes.join(', '));
    }

    renderSchool(school, index) {
        return (
            <div key={index} className="school-grid-item">
                <div className="school-image-container">
                    <Link to={school.getUri()}>
                        <div className="image-holder">
                            <img src={school.getExploreThumbnail()} alt="School campus." key={1} />
                        </div>
                    </Link>
                </div>
                <div className="school-info-container">
                    <p className="school-name">
                        <Link to={school.getUri()}>
                            {school.getName()}
                        </Link>
                    </p>
                    {
                        <p className="school-location">
                            {school.getCityState()}
                        </p>
                    }
                    {
                        <p className="school-acceptance">
                            {this.getAcceptingStudentType(school)}
                        </p>
                    }
                </div>
            </div>
        );
    }

    renderSchoolForPrint(school, index) {
        return (
            <div key={index} className="school-grid-item">
                <div className="school-info-container">
                    <p className="school-name">
                        <Link to={school.getUri()}>
                            {school.getName()}
                        </Link>
                    </p>
                    <p className="school-location">
                        {school.getCityState()}
                    </p>
                    <p className="school-acceptance">
                        {this.getAcceptingStudentType(school)}
                    </p>
                </div>
            </div>
        );
    }
}

ExploreResultsGrid.propTypes = {
    schools: PropTypes.array
};

export default ExploreResultsGrid;
